import { useFormik } from "formik";
import { StatsRequestBody } from "../../types/request/StatsRequest";
import { ReactComponent as BackArrow } from "../../assets/icons/backArrow.svg";
import Daypicker from "./Daypicker";
import { useNavigate } from "react-router-dom";
import InputCheckbox from "../../components/Inputs/InputCheckbox";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useExportLogs } from "../../api/stats";
import Spinner from "../../components/ui/Spinner";
import { useAuth } from "../../contexts/Auth";
import { createExportSchema } from "./validator";
import { useEffect, useState } from "react";
import { STATE_DATE_TYPE, StateDateType, STATS_TYPES } from "../../constants";
import InputRadio from "../../components/Inputs/InputRadio";

export default function Stats(): React.JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedCaisse, selectedWorkspace } = useAuth();

    const { isLoading, mutateAsync } = useExportLogs({
        onSuccess: (data, values) => {
            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0];
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `exported_logs_${values.type}_${formattedDate}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    });

    const [dateType, setDateType] = useState<StateDateType>("day");

    const initialValues: StatsRequestBody = {
        startDate: undefined,
        endDate: undefined,
        type: "",
        etatRecouvrementParCaisse: false,
        recapRecouvrementParCaisse: false,
    };

    const formik = useFormik<StatsRequestBody>({
        initialValues,
        validationSchema: createExportSchema(t),
        onSubmit: async values => {
            if (!values.startDate || !values.endDate) {
                formik.setErrors({
                    startDate: t("required"),
                    endDate: t("required"),
                });
                return;
            }

            await mutateAsync({
                workspaceId: selectedWorkspace?.workspaceId || "",
                registerId: selectedCaisse?.id || "",
                type: values.type,
                isCaisse: !!values[values.type + "ParCaisse" as keyof StatsRequestBody],
                startDate: values.startDate ? new Date(values.startDate.getTime() + (60 * 60 * 1000)).toISOString() : "",
                endDate: values.endDate ? new Date(values.endDate.getTime() + (60 * 60 * 1000)).toISOString() : "",
            });

        }
    });

    useEffect(() => {
        if (formik.values.type !== STATS_TYPES.RECAP_RECOUVREMENT && formik.values.recapRecouvrementParCaisse)
            formik.setFieldValue("recapRecouvrementParCaisse", false);

        if (formik.values.type !== STATS_TYPES.ETAT_RECOUVREMENT && formik.values.etatRecouvrementParCaisse)
            formik.setFieldValue("etatRecouvrementParCaisse", false);
    }, [formik]);

    return (
        <article className="flex items-start">
            <BackArrow
                className="cursor-pointer rtl:rotate-180"
                onClick={() => navigate("/dashboard")}
            />
            <form
                onSubmit={formik.handleSubmit}
                className="flex items-center flex-col gap-10 w-[calc(100%-40px)]">
                <section className="flex items-start gap-4">
                    {Object.values(STATE_DATE_TYPE).map((type) => (
                        <PrimaryButton
                            key={type}
                            type="button"
                            onClick={() => {
                                setDateType(type);
                                formik.setFieldValue("type", "");
                                formik.setFieldValue("startDate", undefined);
                                formik.setFieldValue("endDate", undefined);
                            }}
                            className={dateType !== type ? "!bg-white !border !border-primaryGreen !text-primaryGreen" : ""}
                        >
                            {t(type + "StateType")}
                        </PrimaryButton>
                    ))}

                    <Daypicker
                        onChangeDate={(date) => {
                            formik.setFieldValue("startDate", date);
                            if (!date) return;
                            switch (dateType) {
                                case "day":
                                    formik.setFieldValue("endDate", date);
                                    break;
                                case "month":
                                    formik.setFieldValue("endDate", new Date(date.getFullYear(), date.getMonth() + 1, 0));
                                    break;
                                case "year":
                                    formik.setFieldValue("endDate", new Date(date.getFullYear(), 11, 31));
                                    break;
                            }
                        }}
                        dateType={dateType}
                        value={formik.values.startDate}
                        error={formik.errors.startDate}
                        setError={(error) => formik.setFieldError("startDate", error)}
                    />
                </section>
                <section className="space-y-6">
                    {(["day"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="journalCaisse"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.JOURNAL_CAISSE}
                            value={STATS_TYPES.JOURNAL_CAISSE}
                        />
                    )}

                    {(["day"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="bordereauCaisse"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.BORDEREAU_CAISSE}
                            value={STATS_TYPES.BORDEREAU_CAISSE}
                        />
                    )}

                    {(["day"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="ventilationFiscale"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.VENTILATION_FISCALE}
                            value={STATS_TYPES.VENTILATION_FISCALE}
                        />
                    )}

                    {(["day", "month", "year"] as StateDateType[]).includes(dateType) && (
                        <div className="space-y-2">
                            <InputRadio
                                label="recapRecouvrement"
                                name="type"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    if (e.target.checked)
                                        formik.setFieldValue("recapRecouvrementParCaisse", false);
                                }}
                                checked={formik.values.type === STATS_TYPES.RECAP_RECOUVREMENT}
                                value={STATS_TYPES.RECAP_RECOUVREMENT}
                            />
                            <InputCheckbox
                                label="parCashier"
                                name="recapRecouvrementParCaisse"
                                onChange={(e) => {
                                    formik.handleChange(e);

                                    if (e.target.checked)
                                        formik.setFieldValue("type", STATS_TYPES.RECAP_RECOUVREMENT);
                                }}
                                value={formik.values.recapRecouvrementParCaisse}
                                className="ps-10"
                            />
                        </div>
                    )}

                    {(["day", "month", "year"] as StateDateType[]).includes(dateType) && (
                        <div className="space-y-2">
                            <InputRadio
                                label="etatRecouvrement"
                                name="type"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    if (!e.target.checked)
                                        formik.setFieldValue("etatRecouvrementParCaisse", false);
                                }}
                                checked={formik.values.type === STATS_TYPES.ETAT_RECOUVREMENT}
                                value={STATS_TYPES.ETAT_RECOUVREMENT}
                            />
                            <InputCheckbox
                                label="parCashier"
                                name="etatRecouvrementParCaisse"
                                onChange={(e) => {
                                    formik.handleChange(e);

                                    if (e.target.checked)
                                        formik.setFieldValue("type", STATS_TYPES.ETAT_RECOUVREMENT);
                                }}
                                value={formik.values.etatRecouvrementParCaisse}
                                className="ps-10"
                            />
                        </div>
                    )}

                    {(["day"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="registreCaisse"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.REGISTRE_CAISSE}
                            value={STATS_TYPES.REGISTRE_CAISSE}
                        />
                    )}

                    {(["day"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="duplicataQuitance"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.DUPLICATA_QUITTANCE}
                            value={STATS_TYPES.DUPLICATA_QUITTANCE}
                        />
                    )}

                    {(["day"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="etatDesConstatations"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.ETAT_DES_CONSTATATIONS}
                            value={STATS_TYPES.ETAT_DES_CONSTATATIONS}
                        />
                    )}

                    {(["day", "month", "year"] as StateDateType[]).includes(dateType) && (
                        <InputRadio
                            label="etatVersement"
                            name="type"
                            onChange={formik.handleChange}
                            checked={formik.values.type === STATS_TYPES.ETAT_VERSEMENT}
                            value={STATS_TYPES.ETAT_VERSEMENT}
                        />
                    )}
                </section>


                <PrimaryButton
                    type="submit"
                    disabled={isLoading}
                >

                    {isLoading ? <Spinner /> : t("export")}
                </PrimaryButton>
            </form>
        </article>
    );
}