import { useTranslation } from "react-i18next";
import { ReactComponent as ValidIcon } from "../../assets/icons/valid.svg";

interface InputCheckboxProps {
  name: string;
  label: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export default function InputCheckbox({
  name,
  label,
  value,
  onChange,
  className,
}: InputCheckboxProps): React.JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={"flex items-center gap-2 " + className}>
      <div className="relative">
        <input
          className="peer size-5 cursor-pointer appearance-none rounded-md border border-neutral-300 outline-none checked:bg-primaryGreen focus:border-2 focus:border-primaryGreen"
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChange}
        />
        <ValidIcon className="pointer-events-none absolute left-1/2 top-1/2 hidden h-4 w-4 -translate-x-1/2 -translate-y-2/3 transform text-white peer-checked:block" />
      </div>
      <label className="text-base font-medium text-neutral-800">{t(label)}</label>
    </div>
  );
}
