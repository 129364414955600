import { TFunction } from "i18next";
import Yup from "../utils/customYup";
import { CancelInvoiceRequestBody } from "../types/request/InvoiceRequest";

export function createRevertTransactionsValidation(t: TFunction): Yup.Schema<CancelInvoiceRequestBody> {
    return Yup.object({
        reason: Yup.string().required(t("required")),
        transactionId: Yup.string().required(t("required")),
        registerId: Yup.string().required(t("required")),
    });
}