export const RTL_LANGUAGES = ["ar"];

export const LANGUAGES = ["en", "fr", "ar"] as const;

export const REGEX = {
  PHONE: /^(\+213|0)(5|6|7)[0-9]{8}$/,
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
} as const;

export const CAISSETYPE = ["cash", "cheque", "bankDeposit", "bankTransfer", "tpe", "brigadeDeRecouvrement"] as const;

export const STATE_DATE_TYPE = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
} as const;

export const CANCEL_TRANSACTION_REASON = {
  POWER_CUT: "errorPowerCut",
  ERROR_ADDRESS: "errorAddress",
  ERROR_PAYMENT_MODE: "errorPaymentMode",
  ERROR_MONTH_NUMBER: "errorMonthNumber",
  ERROR_LATE_PENALTY: "errorLatePenalty",
  ERROR_RUBRIQUE_VALUE: "errorRubriqueValue",
  ERROR_PRINTING: "errorPrinting",
} as const;

export const STATS_TYPES = {
  JOURNAL_CAISSE: "journalCaisse",
  BORDEREAU_CAISSE: "bordereauCaisse",
  VENTILATION_FISCALE: "ventilationFiscale",
  RECAP_RECOUVREMENT: "recapRecouvrement",
  ETAT_RECOUVREMENT: "etatRecouvrement",
  ETAT_VERSEMENT: "etatVersement",
  REGISTRE_CAISSE: "registreCaisse",
  DUPLICATA_QUITTANCE: "duplicataQuitance",
  ETAT_DES_CONSTATATIONS: "etatDesConstatations",
} as const;

export type StateDateType = typeof STATE_DATE_TYPE[keyof typeof STATE_DATE_TYPE];
export type CancelTransactionReason = typeof CANCEL_TRANSACTION_REASON[keyof typeof CANCEL_TRANSACTION_REASON];
export type StatsTypes = typeof STATS_TYPES[keyof typeof STATS_TYPES];