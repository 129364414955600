import { useTranslation } from "react-i18next";

interface InputFieldProps {
  type: React.HTMLInputTypeAttribute | "textarea";
  name: string;
  label: string;
  placeholder: string;
  value: React.InputHTMLAttributes<HTMLInputElement>["value"];
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: string;
  className?: string;
  readonly?: boolean;
}

export default function InputField({
  type,
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  className,
  readonly,
}: InputFieldProps): React.JSX.Element {
  const { t } = useTranslation();
  return type === "textarea" ? (
    <div className={"flex flex-col gap-2 " + className}>
      <label className={`text-base font-medium text-neutral-800`}>{t(label)}</label>
      <textarea
        className={`min-h-36 rounded-md border border-neutral-300 bg-white px-1 py-3 font-normal outline-none placeholder:text-sm placeholder:text-neutral-500 focus:border-primaryGreen ${error ? "border-destructive-500 text-destructive-500" : " "}`}
        name={name}
        placeholder={t(placeholder)}
        value={value}
        onChange={onChange}
        readOnly={readonly}
      ></textarea>
      {error && <span className="text-sm text-destructive-500">{error}</span>}
    </div>
  ) : (
    <div className={"flex flex-col gap-2 " + className}>
      <label className={`text-base font-medium text-neutral-800`}>{t(label)}</label>

      <input
        className={`rounded-md border border-neutral-300 bg-white px-4 py-3 font-normal outline-none placeholder:text-sm placeholder:text-neutral-500 focus:border-primaryGreen ${error ? "border-destructive-500 text-destructive-500" : " "}`}
        name={name}
        type={type}
        placeholder={t(placeholder)}
        value={value}
        onChange={onChange}
        readOnly={readonly}
      />
      {error && <span className="text-sm text-destructive-500">{error}</span>}
    </div>
  );
}
