import { useTranslation } from "react-i18next";
import { ReactComponent as LogoIcon } from "../assets/logo/logo.svg";
import { ReactComponent as LogoIconAr } from "../assets/logo/logoAr.svg";

export default function Logo({ className }: { className?: string }): React.JSX.Element {
  const { language } = useTranslation().i18n;

  switch (language) {
    case "ar":
      return <LogoIconAr className={className} />;
    default:
      return <LogoIcon className={className} />;
  }
}
