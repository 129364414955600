import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface PrimaryLinkProps extends LinkProps {
  children: React.ReactNode;
}

export default function PrimaryLink({ children, className, ...props }: PrimaryLinkProps): React.JSX.Element {
  return (
    <Link
      {...props}
      className={
        "rounded-xl bg-primaryGreen px-5 py-3 font-semibold text-white duration-150 hover:scale-105 hover:brightness-110 active:brightness-90 disabled:scale-100 disabled:cursor-pointer disabled:brightness-90 " +
        className
      }
    >
      {children}
    </Link>
  );
}
