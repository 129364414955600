import { useTranslation } from "react-i18next";
import PrimaryLink from "../../components/Buttons/PrimaryLink";

export default function NotFound(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <article className="flex flex-col items-center gap-8 justify-center h-full">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">404</h1>
        <p className="text-xl">{t("notFound")}</p>
        <p className="text-neutral-500 max-w-lg">{t("notFoundDescription")}</p>
      </div>
      <PrimaryLink to="/">{t("backToHome")}</PrimaryLink>
    </article>
  );
}
