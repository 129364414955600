import { useTranslation } from "react-i18next";
import { useGetTenants } from "../../api/tenants";
import Spinner from "../../components/ui/Spinner";
import SearchTenantController from "./controller";
import TenantTable from "./Table";
import { useAuth } from "../../contexts/Auth";
import { ReactComponent as EmptySVG } from "../../assets/emptySvg.svg";
import Pagination from "../../components/Pagination";
import { useSearchParams } from "react-router-dom";

export default function SearchTenant(): React.JSX.Element {
  const { selectedWorkspace } = useAuth();
  const { isLoading, data } = useGetTenants(selectedWorkspace?.workspaceId || "");
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { response: tenants, pageCount } = data ?? {};
  const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

  return (
    <div className="space-y-6">
      <SearchTenantController />
      {isLoading ? (
        <div className="flex h-96 items-center justify-center rounded-xl bg-white">
          <Spinner />
        </div>
      ) : tenants ? (
        <article className="space-y-5">
          <section className="flex items-center justify-between px-4">
            <h2 className="text-xl font-bold">{t("lastSearch")}</h2>
          </section>
          <TenantTable tenants={tenants}
          />

          {pageCount ? (
            <Pagination
              currentPage={currentPage}
              totalPages={pageCount}
            />
          ) : null}
        </article>
      ) : (
        <div className="flex h-96 items-center justify-center rounded-xl bg-white">
          <EmptySVG />
        </div>
      )}
    </div>
  );
}
