import { useTranslation } from "react-i18next";
import usePopup from "../../hooks/usePopup";


interface AlertStatsProps {
    onClose: () => void;
}

export default function AlertStats({ onClose }: AlertStatsProps): React.JSX.Element {
    usePopup();
    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
            <div className="relative z-10 flex w-[500px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg">
                <div className="flex justify-end">
                    <button
                        className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
                        onClick={onClose}
                    >
                        X
                    </button>
                    {/* Alert SVG */}
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-16 text-destructive-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 9v2m0 4h.01"
                            />
                            <circle cx={12} cy={12} r={10} />
                        </svg>
                        <h1 className="text-center text-destructive-500 text-2xl font-semibold">{t("alert")}</h1>
                    </div>

                </div>
                <p className="text-center text-lg">{t("caisseDidntStopToday")}</p>
            </div>
            <div
                className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
                onClick={onClose}
            />
        </div>
    );
}