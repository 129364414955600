import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "./axios";
import { begin, rowsPerPage } from "../utils/rowsPerPage";
import { useQuery, UseQueryOptions } from "react-query";
import { ErrorResponse, ListResponse } from "../types/response";
import { TenantInfoResponseBody } from "../types/response/TenantsResponseBody";
import { ENDPOINTS } from "./endpoints";

export function useGetTenants(
  workspaceId: string,
  options?: Omit<UseQueryOptions<ListResponse<TenantInfoResponseBody>, ErrorResponse>, "queryKey" | "queryFn">,
) {
  const [searchParams] = useSearchParams();

  async function getTenants() {
    const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

    try {
      const response = await axiosInstance.get(ENDPOINTS.TENANTS_GET_CONNECTED_LIST_ENDPOINT, {
        params: {
          begin: begin(currentPage, rowsPerPage),
          count: rowsPerPage,
          search: searchParams.get("q"),
          workspaceId,
        },
      });

      return response.data;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useQuery<ListResponse<TenantInfoResponseBody>, ErrorResponse>(
    ["tenants", searchParams.get("page"), searchParams.get("q"), workspaceId],
    getTenants,
    {
      ...options,
    },
  );
}

export function useGetTenant(
  id: string,
  workspaceId: string,
  options?: Omit<UseQueryOptions<TenantInfoResponseBody, ErrorResponse>, "queryKey" | "queryFn">,
) {
  async function getTenant() {
    try {
      const response = await axiosInstance.get(
        ENDPOINTS.TENANTS_GET_TENANT_DETAILED_INFOS_ENDPOINT + `${workspaceId}/${id}`,
      );

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useQuery<TenantInfoResponseBody, ErrorResponse>(["tenant", id, workspaceId], getTenant, {
    ...options,
  });
}
