import { useTranslation } from "react-i18next";
import { useAuth } from "../contexts/Auth";

export default function LogoutBtn(): React.JSX.Element {
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <div
      className="cursor-pointer font-semibold hover:text-primaryGreen"
      onClick={logout}
    >
      {t("logout")}
    </div>
  );
}
