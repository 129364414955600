import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { ErrorResponse } from "../types/response";
import { CaisseTimeFrameLog, StatsExportRequestBody } from "../types/request/StatsRequest";
import { ENDPOINTS } from "./endpoints";
import { axiosInstance } from "./axios";
import { LogsResponseBody } from "../types/response/StatsResponse";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";

export function useFrameLog(
    data: CaisseTimeFrameLog,
    options?: Omit<UseQueryOptions<LogsResponseBody[], ErrorResponse>, "queryKey" | "queryFn">
) {

    async function getFrameLog() {
        try {
            const response = await axiosInstance.post(ENDPOINTS.CASH_REGISTER_TIME_FRAME_ENDPOINT, data);

            return response.data.response;
        } catch (error: any) {
            throw error.response.data;
        }
    }

    return useQuery<LogsResponseBody[], ErrorResponse>(["frameLog", data], getFrameLog, {
        ...options,
    });
}

export function useExportLogs(
    options?: Omit<UseMutationOptions<Blob, ErrorResponse, StatsExportRequestBody>, "mutationFn">
) {

    async function exportLog(values: StatsExportRequestBody) {
        try {
            const response = await axiosInstance.post(ENDPOINTS.CASH_REGISTER_EXPORT_LOGS_ENDPOINT, values, {
                responseType: "blob",
            });

            return response.data;
        } catch (error: any) {
            throw error.response.data;
        }
    }

    return useMutation<Blob, ErrorResponse, StatsExportRequestBody>(exportLog, {
        onError: (error) => {
            toast.error(<SubmitResponseHandler {...error} />);
        },
        ...options,
    });
}