import { Link, useSearchParams } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../assets/icons/chevronDown.svg";
import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  maxPagesToShow?: number;
}

export default function Pagination({
  currentPage,
  totalPages,
  maxPagesToShow = 5,
}: PaginationProps): React.JSX.Element {
  const renderPageButtons = () => {
    const paginationPages = generatePagination(currentPage, totalPages, maxPagesToShow);

    return (
      <>
        {paginationPages.map((page, index) =>
          page === "..." ? (
            <span
              key={index}
              className={"flex h-8 w-14 flex-col items-center justify-center text-gray-400"}
            >
              ...
            </span>
          ) : (
            <Link
              key={index}
              to={"?" + new URLSearchParams({ ...searchParams, page: page.toString() }).toString()}
              className={`mx-0.5 flex h-8 w-12 flex-col items-center justify-center rounded border text-xs duration-150 hover:scale-105 ${
                currentPage === page
                  ? "scale-90 border-primaryGreen bg-primaryGreen text-white shadow-md"
                  : "border-primaryGreen bg-white text-primaryGreen"
              }`}
            >
              {page}
            </Link>
          ),
        )}
      </>
    );
  };
  const [searchParams] = useSearchParams();
  const isBegin: boolean = currentPage > 1;
  const hasNext: boolean = currentPage < totalPages;

  if (totalPages === 1)
    return (
      <section className={`flex justify-center py-2`}>
        <nav className="inline-flex rounded bg-transparent">
          <Link
            to="#"
            className="mx-1 flex h-8 w-12 scale-105 flex-col items-center justify-center rounded border border-primaryGreen bg-primaryGreen text-white shadow-md duration-150"
          >
            1
          </Link>
        </nav>
      </section>
    );

  if (totalPages > 1)
    return (
      <section className={`flex justify-center py-2`}>
        <nav className="inline-flex rounded bg-transparent">
          <Link
            to={`?${isBegin ? new URLSearchParams({ ...searchParams, page: (currentPage - 1).toString() }).toString() : "#"}`}
            className={`mr-2 flex h-8 w-12 flex-col items-center justify-center rounded-l border border-primaryGreen bg-primaryGreen text-white shadow-md ${
              isBegin ? `rounded-l-md` : `pointer-events-none opacity-40`
            }`}
          >
            <ArrowIcon className="h-3 w-3 rotate-90" />
          </Link>
          {renderPageButtons()}
          <Link
            to={`?${
              hasNext
                ? new URLSearchParams({ ...searchParams, page: (Number(currentPage) + 1).toString() }).toString()
                : ""
            }`}
            className={`ml-2 flex h-8 w-12 flex-col items-center justify-center rounded-r border border-primaryGreen bg-primaryGreen text-white shadow-md ${
              hasNext ? `rounded-r-md` : `pointer-events-none opacity-40`
            }`}
          >
            <ArrowIcon className="h-3 w-3 -rotate-90" />
          </Link>
        </nav>
      </section>
    );

  return <></>;
}

export function generatePagination(currentPage: number, totalPages: number, maxPagesToShow = 5): (number | "...")[] {
  const pages: (number | "...")[] = [];

  if (totalPages <= maxPagesToShow) for (let i = 1; i <= totalPages; i++) pages.push(i);
  else {
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push("...");
    }

    for (let i = startPage; i <= endPage; i++) pages.push(i);

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push("...");

      pages.push(totalPages);
    }
  }

  return pages;
}
