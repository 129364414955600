import { TFunction } from "i18next";
import Yup from "../../utils/customYup";
import { RubriqueRequestBody } from "../../types/request/RubriqueRequest";

export function createRegisterRubriqueSchema(t: TFunction, isLimitSurpassed: boolean): Yup.Schema<RubriqueRequestBody> {
    return Yup.object({
        workspaceId: Yup.string().required(t("required")),
        registryId: Yup.string().required(t("required")),
        tenantId: Yup.string().required(t("required")),
        rubriqueId: Yup.string().required(t("required")).test("isLimitSurpassed", t("limitSurpassed"), function () {
            return !isLimitSurpassed;
        }),

        amount: Yup.number().typeError(t("required")).required(t("required")).min(1, t("minValue", { value: 1 })),
    });
}