import React from "react";
import { useTranslation } from "react-i18next";
import { RTL_LANGUAGES } from "./constants";
import { Toaster, ToasterProps } from "react-hot-toast";
import RouterComponent from "./router/Router";
import Providers from "./components/Providers";

const ToasterConfig: ToasterProps = {
  position: "bottom-center",
};

export default function App(): React.JSX.Element {
  const { language } = useTranslation().i18n;

  return (
    <Providers>
      <div
        dir={RTL_LANGUAGES.includes(language) ? "rtl" : "ltr"}
        className="h-screen w-screen text-primaryBlack"
      >
        <RouterComponent />
        <Toaster {...ToasterConfig} />
      </div>
    </Providers>
  );
}
