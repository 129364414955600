import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as SearchGlassIcon } from "../../assets/icons/searchGlass.svg";
import { useDebouncedCallback } from "use-debounce";

export default function SearchTenantController(): React.JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const handleSearch = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;
    const params = new URLSearchParams(searchParams.toString());

    if (q) params.set("q", q);
    else params.set("q", "");

    params.delete("page");

    setSearchParams(params);
  }, 300);

  return (
    <section className="flex w-full flex-col items-center justify-center gap-5">
      <h1 className="text-center text-3xl font-bold">{t("searchTenants")}</h1>
      <div className="relative">
        <SearchGlassIcon className="absolute start-4 top-1/2 size-6 -translate-y-1/2 text-primaryGray" />
        <input
          type="search"
          className="w-96 rounded-xl px-12 py-5 outline-none focus:border focus:border-primaryGreen sm:w-[600px]"
          placeholder={t("searchBy")}
          defaultValue={searchParams.get("q") || undefined}
          onChange={handleSearch}
        />
      </div>
    </section>
  );
}
