import { useEffect } from "react";

export default function usePopup() {
  useEffect(() => {
    const body = document.querySelector("body");
    body?.classList.add("overflow-hidden");

    return () => {
      body?.classList.remove("overflow-hidden");
    };
  }, []);
}
