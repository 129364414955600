import Navbar from "../Navbar";
import { Outlet } from "react-router-dom";

export default function DashboardLayout(): React.JSX.Element {
  return (
    <article className="h-screen bg-lightWhite">
      <Navbar />
      <main className="bg-lightWhite px-10 py-8">
        <Outlet />
      </main>
    </article>
  );
}
