import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronDown } from "../assets/icons/chevronDown.svg";
import { LANGUAGES } from "../constants";

export default function LanguageModal(): React.JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const languageRef = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();

  function handleChangeLanguage(lang: string) {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (languageRef.current && !languageRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative"
      ref={languageRef}
    >
      <section
        className="flex items-center gap-2 font-normal"
        onClick={() => setIsOpen(prev => !prev)}
        role="button"
        aria-roledescription="display language options"
      >
        <span className="capitalize">{t(i18n.language + "Full")}</span>
        <ChevronDown className={`duration-150 ${isOpen ? "rotate-180" : "rotate-0"}`} />
      </section>
      <section
        className={`dropdownArrow absolute -end-3 top-7 min-w-32 rounded-md border border-gray-200 bg-white shadow-md after:absolute after:-top-1 after:end-3 after:size-2 after:rotate-45 after:border-l after:border-t after:border-gray-200 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {LANGUAGES.map(lang => (
          <section
            key={lang}
            className="flex items-center gap-2 p-2 font-medium hover:bg-gray-100 md:text-xl"
            onClick={() => handleChangeLanguage(lang)}
            role="button"
            aria-roledescription="change language"
          >
            <span className="text-sm capitalize">{t(lang + "Full")}</span>
          </section>
        ))}
      </section>
    </div>
  );
}
