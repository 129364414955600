import { useTranslation } from "react-i18next";
import PrimaryLink from "../../components/Buttons/PrimaryLink";
import { useAuth } from "../../contexts/Auth";

export default function NoCaisse(): React.JSX.Element {
    const { t } = useTranslation();
    const { logout } = useAuth();

    return (
        <article className="flex flex-col items-center justify-center h-screen w-screen gap-6 text-center">
            <div className="pt-20">
                <h1 className="text-2xl md:text-3xl xl:text-4xl font-bold mb-4">{t("noSelectedCaisse")}</h1>
                <p>{t("noSelectedCaisseDesc")}</p>
                <p>{t("contactAdminCaisse")}</p>
            </div>

            <div className="flex flex-col gap-4">
                <PrimaryLink to="/dashboard" className="min-w-56 text-center">
                    {t("goBackHome")}
                </PrimaryLink>
                <PrimaryLink to="/login"
                    onClick={() => logout()}
                    className="min-w-56 text-center">
                    {t("login")}
                </PrimaryLink>
            </div>
        </article>
    );
}