import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../contexts/Auth";

const queryClient = new QueryClient();

export default function Providers({ children }: { children: React.ReactNode }): React.JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>{children}</AuthProvider>
    </QueryClientProvider>
  );
}
