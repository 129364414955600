import { useMutation, UseMutationOptions } from "react-query";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import { ErrorResponse } from "../types/response";

/**
 * This function is used as placeholder to delete a rubrique
 * @returns 
 */
export function useDeleteRubriquePing(
    options?: Omit<UseMutationOptions<unknown, ErrorResponse, string>, "mutationFn">
) {

    async function deleteAcquirer() {
        try {
            const response = await axiosInstance.get(ENDPOINTS.PING_ENDPOINT);

            return response.data.response;
        } catch (error: any) {
            throw error?.response?.data;
        }
    }

    return useMutation<unknown, ErrorResponse, string>(deleteAcquirer, {

        ...options,
    });
}