import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ErrorResponse } from "../types/response";
import { RubriqueResponseBody } from "../types/response/RubriqueResponse";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import { RubriqueRequestBody } from "../types/request/RubriqueRequest";
import { useAuth } from "../contexts/Auth";

export function useGetTenantRubriques(
    tenantId: string,
    options?: Omit<UseQueryOptions<RubriqueResponseBody[], ErrorResponse>, "queryKey" | "queryFn">
) {

    async function getRubriques() {
        try {
            const response = await axiosInstance.get(ENDPOINTS.TENANT_RUBRIQUE_GET_LIST_ENDPOINT + tenantId);

            return response.data.response;
        } catch (error: any) {
            throw error.response.data;
        }
    }

    return useQuery<RubriqueResponseBody[], ErrorResponse>(["tenantRubriques", tenantId], getRubriques, {
        ...options,
    });

}

export function useCreateRubrique(
    options?: Omit<UseMutationOptions<unknown, ErrorResponse, RubriqueRequestBody>, "mutationFn">
) {
    const queryClient = useQueryClient();

    async function createRubrique(values: RubriqueRequestBody) {
        try {
            const response = await axiosInstance.post(ENDPOINTS.CASH_REGISTER_RUBRIQUE_ENDPOINT, values);

            return response.data.response;
        } catch (error: any) {
            throw error.response.data;
        }
    }

    return useMutation<unknown, ErrorResponse, RubriqueRequestBody>(createRubrique, {
        onSuccess: () => {
            queryClient.invalidateQueries("tenantRubriques");
        },
        ...options,
    });

}

export function useGetRubriques(
    options?: Omit<UseQueryOptions<RubriqueResponseBody[], ErrorResponse>, "queryKey" | "queryFn">
) {
    const { workspaceId } = useAuth().selectedWorkspace || { workspaceId: "" };

    async function getRubriques() {
        try {
            const response = await axiosInstance.get(ENDPOINTS.RUBRIQUE_GET_LIST_ENDPOINT + workspaceId, {
                params: {
                    begin: 0,
                    count: 9999,
                }
            });

            return response.data.response;
        } catch (error: any) {
            throw error.response.data;
        }
    }

    return useQuery<RubriqueResponseBody[], ErrorResponse>(["rubriques", workspaceId], getRubriques, {
        ...options,
    });

}

export function useDeleteRubrique(
    options?: Omit<UseMutationOptions<unknown, ErrorResponse, string>, "mutationFn">
) {
    const queryClient = useQueryClient();
    const { id: caisseId } = useAuth().selectedCaisse || { id: "" };

    async function deleteAcquirer(id: string) {
        try {
            const response = await axiosInstance.delete(ENDPOINTS.CASH_REGISTER_RUBRIQUE_ENDPOINT + caisseId + "/" + id);

            return response.data.response;
        } catch (error: any) {
            throw error?.response?.data;
        }
    }

    return useMutation<unknown, ErrorResponse, string>(deleteAcquirer, {
        onSuccess: () => {
            queryClient.invalidateQueries("rubriques");
            queryClient.invalidateQueries("tenantRubriques");
        },
        ...options,
    });
}