import { useTranslation } from "react-i18next";
import PrimaryButton from "./Buttons/PrimaryButton";
import Spinner from "./ui/Spinner";
import usePopup from "../hooks/usePopup";
import { useFormik } from "formik";
import { useCancelLatestTransactions, useGetLatestTransactions } from "../api/invoices";
import { CancelInvoiceRequestBody } from "../types/request/InvoiceRequest";
import { useAuth } from "../contexts/Auth";
import { TextMoneyFormat } from "../utils/TextMoneyFormat";
import { formatCreatedAtDate } from "../utils/formatDates";
import InputSelect from "./Inputs/InputSelect";
import { CANCEL_TRANSACTION_REASON } from "../constants";
import { createRevertTransactionsValidation } from "./RevertTransactionsValidation";
import toast from "react-hot-toast";

interface RevertTransactionsProps {
    onClose: () => void;
}


export default function RevertTransactions({ onClose }: RevertTransactionsProps): React.JSX.Element {
    usePopup();
    const { t } = useTranslation();
    const { id } = useAuth().selectedCaisse || { id: "" };

    const { data: transactions, isLoading: isLoadingTransactions, isError } = useGetLatestTransactions({
        retry: false,
    });
    const { isLoading, mutate: cancelTransactions } = useCancelLatestTransactions();

    const initialValues: CancelInvoiceRequestBody = {
        reason: "",
        transactionId: transactions?.id || "",
        registerId: id,
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: createRevertTransactionsValidation(t),
        onSubmit: (values) => {
            cancelTransactions(values, {
                onSuccess: () => {
                    toast.success(t("transactionReverted"));
                    onClose();
                }
            });
        }
    });

    const renderField = (label: string, value: string | number) => (
        <div className="flex w-full items-center gap-x-4">
            <p className="min-w-[150px] text-sm font-bold text-primaryGray lg:text-sm">{label}</p>
            <p className="text-sm text-primaryBlack lg:text-base">{value ? value : "0.00 DZD"}</p>
        </div>
    );

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
            <div className="relative z-10 flex w-[700px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg">
                <div className="flex justify-end">
                    <button
                        className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
                        onClick={onClose}
                    >
                        X
                    </button>
                    <h1 className="text-center text-destructive-500 text-2xl font-semibold">{t("revertTransaction")}</h1>
                </div>
                {isLoadingTransactions ?
                    <div className="flex justify-center">
                        <Spinner />
                    </div> :
                    !isError && transactions ? (
                        <form className="w-full space-y-8" onSubmit={formik.handleSubmit}>
                            <section className="space-y-4 grid grid-cols-2">
                                <div className="font-bold text-lg col-span-2">{t("tenantInfos")}:</div>
                                <div className="w-full flex-col rounded-md p-4 col-span-2">
                                    {renderField(t("fullName"), transactions.tenantFullName)}
                                    {renderField(t("tenantCode"), transactions.tenantMatricule)}
                                    {renderField(t("address"), transactions.tenantAddress)}
                                </div>
                                <div className="font-bold text-lg col-span-2">{t("transactionInfo")}:</div>
                                <div className="w-full flex-col rounded-md  p-4 col-span-2">
                                    {renderField(t("paymentDate"), transactions.paidAt.replace("T", " "))}
                                    {renderField(t("price"), TextMoneyFormat(transactions.amount, t))}
                                    {renderField(t("from"), formatCreatedAtDate(t, transactions.fromDate))}
                                    {renderField(t("to"), formatCreatedAtDate(t, transactions.toDate))}
                                    {renderField(t("paidMonthsNumber"), transactions.months ? transactions.months + " " + t("months") : "/")}
                                </div>
                                <InputSelect
                                    name="reason"
                                    label="reason"
                                    onChange={formik.handleChange}
                                    options={Object.values(CANCEL_TRANSACTION_REASON).map((reason) => ({ value: reason, label: t(reason) }))}
                                    value={formik.values.reason}
                                    className="col-span-2 [&>select>option]:font-bold [&>select>option]:uppercase"
                                    error={formik.errors.reason}
                                    placeholder="selectReason"
                                />
                            </section>
                            <section className="flex justify-center gap-4">
                                <PrimaryButton
                                    type="reset"
                                    onClick={onClose}
                                    className="!bg-destructive-500"
                                >
                                    {t("cancel")}
                                </PrimaryButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spinner /> : t("confirm")}
                                </PrimaryButton>
                            </section>
                        </form>
                    ) : (
                        <div className="flex justify-center">
                            <p className="text-lg font-semibold text-primaryBlack">{t("noTransactionAvailable")}</p>
                        </div>
                    )}
            </div>
            <div
                className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
                onClick={onClose}
            />
        </div>
    );
}