import React, { ButtonHTMLAttributes } from "react";

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export default function PrimaryButton({ children, className, ...props }: PrimaryButtonProps): React.JSX.Element {
  return (
    <button
      {...props}
      className={
        "bg-primaryGreen rounded-xl px-5 py-3 font-semibold text-white duration-150 hover:scale-105 hover:brightness-110 active:brightness-90 disabled:scale-100 disabled:cursor-pointer disabled:brightness-90 " +
        className
      }
    >
      {children}
    </button>
  );
}
