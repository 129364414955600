import * as Yup from 'yup';
import { ChangePasswordRequestBody, ClientProfileRequestBody } from '../../types/request/UserRequest';
import { TFunction } from 'i18next';

export function createProfilePasswordSchema(t: TFunction): Yup.Schema<ChangePasswordRequestBody> {
  return Yup.object({
    oldPassword: Yup.string()
      .max(255, `${t("maxLength", { value: 255 })}`)
      .min(5, `${t('minLength', { value: 5 })}`)
      .required(t('required')),
    password: Yup.string()
      .max(255, `${t("maxLength", { value: 255 })}`)
      .min(5, `${t('minLength', { value: 5 })}`)
      .required(t('required')),
    confirmPassword: Yup.string()
      .required(t('required'))
      .test('passwords-match', `${t('passwordMatchmErr')}`, function (value) {
        if (this.parent.password && value !== this.parent.password) {
          return false;
        }
        if (!this.parent.password && value) {
          return false;
        }
        return true;
      }),
  });
}

export function createProfileUpdateSchema(t: TFunction): Yup.Schema<ClientProfileRequestBody> {
  return Yup.object({
    firstName: Yup.string()
      .max(255, `${t("maxLength", { value: 255 })}`)
      .min(3, `${t('minLength', { value: 3 })}`)
      .required(`${t('required')}`),
    lastName: Yup.string()
      .max(255, `${t("maxLength", { value: 255 })}`)
      .min(3, `${t('minLength', { value: 3 })}`)
      .required(`${t('required')}`)
  });
}