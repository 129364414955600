import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useGetProfile, useUpdateProfile } from '../../api/user';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import InputField from '../../components/Inputs/InputField';
import Spinner from '../../components/ui/Spinner';
import { ClientProfileRequestBody } from '../../types/request/UserRequest';
import { createProfileUpdateSchema } from './ProfileValidation';

export default function ProfileInfoForm(): React.JSX.Element {

  const { data: user } = useGetProfile();

  const { isLoading, mutate: updateProfile } = useUpdateProfile();

  const { t } = useTranslation();

  const initialValues: ClientProfileRequestBody = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  };

  const formik = useFormik<ClientProfileRequestBody>({
    initialValues,
    validationSchema: createProfileUpdateSchema(t),
    enableReinitialize: true,
    onSubmit: async values => {
      updateProfile(values, {
        onSuccess: () => {
          toast.success(t('profileUpdated'));
        },
      });
    }
  });

  return (
    <form
      className="flex flex-col gap-6 px-4 pt-16 max-lg:items-center lg:gap-8 lg:px-[10%]"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex w-full flex-col gap-4 lg:gap-6">
        <h2 className="font-semibold text-2xl text-textcolor">{t('profile')}</h2>
        <div className="grid grid-cols-1 gap-x-20 gap-y-8 lg:grid-cols-2 ">
          <InputField
            name="firstName"
            label="firstName"
            type="text"
            placeholder="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            error={formik.errors.firstName}
          />

          <InputField
            name="lastName"
            label="lastName"
            type="text"
            placeholder="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.errors.lastName}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 ">
        <PrimaryButton
          type="reset"
          className='!bg-white !text-primaryBlack !border !border-primaryBlack'
        >
          {t("cancel")}
        </PrimaryButton>
        <PrimaryButton
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <Spinner className='mx-auto' /> : t('update')}
        </PrimaryButton>
      </div>
    </form>
  );
}
