import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";
import PrimaryButton from "./Buttons/PrimaryButton";
import Spinner from "./ui/Spinner";
import { ReactComponent as DeleteAlertIcon } from '../assets/icons/deleteAlert.svg';
import toast from "react-hot-toast";
import usePopup from "../hooks/usePopup";
import { ErrorResponse } from "../types/response";


interface DeleteModalProps {
    id: string;
    onClose: () => void;
    deleteCall: UseMutationResult<unknown, ErrorResponse, string>;
}

export default function DeleteAlertModal({ id, onClose, deleteCall }: DeleteModalProps): React.JSX.Element {
    usePopup();

    const { t } = useTranslation();
    const { isLoading, mutate } = deleteCall;


    return (
        <div className="fixed -top-6 inset-0 z-50 backdrop-blur-sm flex justify-center items-center ">
            <div className="absolute inset-0 bg-lightGray opacity-30"></div>
            <div className="flex flex-col gap-y-6 items-center bg-white rounded-lg p-8 z-10  shadow-primaryBlack/30 shadow-lg w-[400px]">
                <div className="flex flex-col items-center gap-y-3">
                    <DeleteAlertIcon />
                    <h2 className="text-center text-lg text-primaryBlack">{t('deleteAlert')}</h2>
                </div>
                <div className="w-full flex justify-center items-center gap-x-4">
                    <PrimaryButton
                        disabled={isLoading}
                        onClick={() => mutate(id, {
                            onSuccess: () => {
                                onClose();
                                toast.success(t('deleteSuccess'));
                            }
                        })}
                    >
                        {isLoading ? <Spinner /> : t('delete')}
                    </PrimaryButton>
                    <PrimaryButton
                        type="reset"
                        onClick={onClose}
                        disabled={isLoading}
                        className="!bg-white !text-primaryGreen border !border-primaryGreen"
                    >
                        {t('cancel')}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}