import { useTranslation } from "react-i18next";
import usePopup from "../../../hooks/usePopup";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Spinner from "../../../components/ui/Spinner";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";

interface ConfirmPopupProps {
    onClose: () => void;
    isLoading: boolean;
    totalQuote: number
}

export default function ConfirmPopup({ onClose, isLoading, totalQuote }: ConfirmPopupProps): React.JSX.Element {
    usePopup();

    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
            <div className="relative z-10 flex w-[500px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg">
                <div className="flex justify-end">
                    <button
                        className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
                        onClick={onClose}
                    >
                        X
                    </button>
                    <div className="space-y-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-16 mx-auto text-primaryGreen"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 9v2m0 4h.01"
                            />
                            <circle cx={12} cy={12} r={10} />
                        </svg>
                        <h1 className="text-center text-neutral-800 text-xl font-semibold">{t("confirmPayment")}</h1>
                        <h2 className="text-center text-primaryGreen text-2xl font-semibold">{TextMoneyFormat(totalQuote || 0, t)}</h2>
                    </div>
                </div>
                <section className="flex justify-center gap-4">
                    <PrimaryButton
                        type="button"
                        onClick={onClose}
                        className="!bg-destructive-500"
                    >
                        {t("cancel")}
                    </PrimaryButton>
                    <PrimaryButton
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? <Spinner /> : t("confirm")}
                    </PrimaryButton>
                </section>
            </div>
            <div
                className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
                onClick={onClose}
            />
        </div>
    );
}