import { useTranslation } from "react-i18next";

interface InputRadioProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export default function InputRadio({ name, value, checked, onChange, label }: InputRadioProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-2">
      <div
        className={`flex size-5 cursor-pointer items-center justify-center rounded-full border ${checked ? "border-primaryGreen" : "border-neutral-300"}`}
        onClick={() => onChange({ target: { name, value, checked: !checked } } as React.ChangeEvent<HTMLInputElement>)}
      >
        <div className={`size-3 rounded-full ${checked ? "bg-primaryGreen" : "bg-transparent"}`}></div>
      </div>
      <span>{t(label)}</span>
    </div>
  );
}
