import * as Yup from "yup";
import { REGEX } from "../constants";

Yup.addMethod(Yup.string, "minTrim", function (min: number, message: string) {
  return this.test("min-trim", message, value => {
    const trimmedValue = value?.replace(/\s/g, "") ?? "";
    return trimmedValue.length >= min;
  });
});

Yup.addMethod(Yup.string, "isPhone", function (message: string) {
  return this.test("is-phone", message, value => {
    return !!value?.match(REGEX.PHONE);
  });
});

Yup.addMethod(Yup.string, "isFutureDate", function (message: string) {
  return this.test("is-future-date", message, value => {
    return value ? new Date(value) > new Date() : true;
  });
});

export default Yup;
