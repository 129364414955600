import { useTranslation } from "react-i18next";
import { TranslateValue } from "./TranslateValueUtils";

export function TranslateValueComponet({ value, valueAr, valueFr }: TranslateValue): React.JSX.Element {
    const { language } = useTranslation().i18n;

    switch (language) {
        case "en":
        default:
            return <>{value}</>;
        case 'ar':
            return <>{valueAr}</>;
        case 'fr':
            return <>{valueFr}</>;
    }

}