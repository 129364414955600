import {
  CaisseBrigadeRequestBody,
  CaisseChequeResponseBody,
  CaisseEspeceResponseBody,
  CaisseTpuRequestBody,
  CaisseVirementResponseBody,
} from "../../../types/request/CaisseRequest";
import Yup from "../../../utils/customYup";

export function createChequeValidator(
  t: (key: string) => string,
  min: number,
  max: number,
): Yup.Schema<CaisseChequeResponseBody> {
  return Yup.object().shape({
    bank: Yup.string().required(t("required")).minTrim(3, t("minLength3")),
    chequeNumber: Yup.string().required(t("required")),
    months: Yup.number()
      .required(t("required"))
      .integer(t("integer"))
      .positive(t("positive"))
      .typeError(t("required"))
      .min(min, t("minMonths") + min)
      .max(max, t("maxMonths") + max),
    date: Yup.string().required(t("required")),
    creditNumber: Yup.string().default(null).nullable(),
  });
}

export function createEspeceValidator(
  t: (key: string) => string,
  min: number,
  max: number,
): Yup.Schema<CaisseEspeceResponseBody> {
  return Yup.object().shape({
    price: Yup.number()
      .positive(t("positive"))
      .test("moreThanHandedOver", t("moreThanHandedOver"), function (value) {
        if (!value) return true;
        return value > this.parent.handedOver;
      }).nullable().default(null),
    handedOver: Yup.number().min(0, t("positive")).nullable().default(null),
    months: Yup.number()
      .required(t("required"))
      .integer(t("integer"))
      .positive(t("positive"))
      .typeError(t("required"))
      .min(min, t("minMonths") + min)
      .max(max, t("maxMonths") + max),
  });
}

export function createVirementValidator(
  t: (key: string) => string,
  min: number,
  max: number,
): Yup.Schema<CaisseVirementResponseBody> {
  return Yup.object().shape({
    bank: Yup.string().required(t("required")).minTrim(3, t("minLength3")),
    virementNumber: Yup.string().required(t("required")),
    months: Yup.number()
      .required(t("required"))
      .integer(t("integer"))
      .positive(t("positive"))
      .typeError(t("required"))
      .min(min, t("minMonths") + min)
      .max(max, t("maxMonths") + max),
    date: Yup.string().required(t("required"))
  });
}

export function createTpuValidator(
  t: (key: string) => string,
  min: number,
  max: number,
): Yup.Schema<CaisseTpuRequestBody> {
  return Yup.object().shape({
    numero: Yup.string().required(t("required")),
    date: Yup.string().required(t("required")),
    months: Yup.number()
      .required(t("required"))
      .integer(t("integer"))
      .positive(t("positive"))
      .typeError(t("required"))
      .min(min, t("minMonths") + min)
      .max(max, t("maxMonths") + max),
  });
}

export function createBrigadeValidator(
  t: (key: string) => string,
  min: number,
  max: number,
): Yup.Schema<CaisseBrigadeRequestBody> {
  return Yup.object().shape({
    numero: Yup.string().required(t("required")),
    date: Yup.string().required(t("required")),
    months: Yup.number()
      .required(t("required"))
      .integer(t("integer"))
      .positive(t("positive"))
      .typeError(t("required"))
      .min(min, t("minMonths") + min)
      .max(max, t("maxMonths") + max),
    accountId: Yup.string().required(t("required")),
  });
}