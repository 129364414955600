import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/Auth";
import { useQueryClient } from "react-query";

interface ChangeWorkSpaceModalProps {
  onClose: () => void;
}

const ChangeWorkSpaceModal: React.FC<ChangeWorkSpaceModalProps> = ({ onClose }) => {
  const { linkedWorkspaces, selectedWorkspace, setSelectedWorkspace } = useAuth();
  const { t } = useTranslation();
  const queryClient = useQueryClient();


  const handleWorkspaceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const workspaceName = e.target.value;
    const selected = linkedWorkspaces?.find(ws => ws.workspaceName === workspaceName);

    if (selected) {
      setSelectedWorkspace(selected);

    }
    else console.error("Workspace not found");
  };

  const handleClose = () => {
    onClose();
    queryClient.invalidateQueries("caisses");

  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
      <div className="relative z-10 flex w-[500px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg">
        <div className="flex justify-end">
          <button
            className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
            onClick={handleClose}
          >
            X
          </button>
          <h1>{t("updateWorkspace")}</h1>
        </div>
        <form className="w-full">
          <select
            className="h-12 w-full cursor-pointer rounded-lg border px-4 text-base font-bold text-primaryBlack outline-none"
            id="workspace"
            name="workspace"
            value={selectedWorkspace?.workspaceName || ""}
            onChange={handleWorkspaceChange}
          >
            {linkedWorkspaces &&
              linkedWorkspaces.map(option => (
                <option
                  key={option.workspaceDescription}
                  value={option.workspaceName}
                >
                  {option.workspaceDescription}
                </option>
              ))}
          </select>
        </form>
      </div>
      <div
        className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
        onClick={handleClose}
      />
    </div>
  );
};

export default ChangeWorkSpaceModal;
