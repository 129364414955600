import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

interface CaisseContextType {
  maxAllowedNumberOfMonthsToPay: number;
  minAllowedNumberOfMonthsToPay: number;
  tenantId: string;
  tenantName: string;
  lastPaidDate: string;
  transactionRefrence?: string;
  numberOfMonthsToPay: number;
  rubriques: string[];
  setTransactionRefrence: Dispatch<SetStateAction<string>>;
  setRubriques: Dispatch<SetStateAction<string[]>>;
}

interface CaisseProviderProps {
  children: React.ReactNode;
  maxAllowedNumberOfMonthsToPay: number;
  minAllowedNumberOfMonthsToPay: number;
  tenantId: string;
  tenantName: string;
  lastPaidDate: string;
  numberOfMonthsToPay: number;
  rubriques: string[];
}

export const CaisseContext = createContext<CaisseContextType | null>(null);

export function CaisseProvider({
  children,
  maxAllowedNumberOfMonthsToPay,
  minAllowedNumberOfMonthsToPay,
  tenantId,
  tenantName,
  lastPaidDate,
  numberOfMonthsToPay,
  rubriques: rubriquesProp
}: CaisseProviderProps): React.JSX.Element {
  const [transactionRefrence, setTransactionRefrence] = useState<string>("");
  const [rubriques, setRubriques] = useState<string[]>([...rubriquesProp]);


  return (
    <CaisseContext.Provider
      value={{
        maxAllowedNumberOfMonthsToPay,
        minAllowedNumberOfMonthsToPay,
        tenantId,
        tenantName,
        setTransactionRefrence,
        transactionRefrence,
        lastPaidDate,
        numberOfMonthsToPay,
        rubriques,
        setRubriques
      }}
    >
      {children}
    </CaisseContext.Provider>
  );
}

export function useCaisse(): CaisseContextType {
  const context = useContext(CaisseContext);

  if (!context) {
    throw new Error("useCaisse must be used within a CaisseProvider");
  }

  return context;
}
