import React from "react";
import { useTranslation } from "react-i18next";

interface InputSelectProps {
  label?: string;
  options: {
    value: string;
    label: string;
  }[];
  name: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: string;
  className?: string;
  translate?: boolean;
  readonly?: boolean;
}

export default function InputSelect({
  label,
  options,
  name,
  placeholder,
  value,
  onChange,
  error,
  className,
  translate = true,
  readonly,
}: InputSelectProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={"flex flex-col gap-2 " + className}>
      {label && <label className="text-base font-medium">{t(label)}</label>}
      <select
        className={`rounded-md border border-neutral-200 px-3 py-2.5 font-normal outline-none placeholder:text-sm placeholder:text-neutral-500 ${readonly ? "bg-gray-100" : "bg-white focus:border-primaryGreen"}`}
        name={name}
        value={value}
        onChange={
          readonly
            ? undefined
            : onChange
        }
      >
        {placeholder && (
          <option
            value=""
            disabled
          >
            {t(placeholder)}
          </option>
        )}
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {translate ? t(option.label) : option.label}
          </option>
        ))}
      </select>
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
}
