import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { createProfilePasswordSchema } from './ProfileValidation';
import { ChangePasswordRequestBody } from '../../types/request/UserRequest';
import InputField from '../../components/Inputs/InputField';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { useUpdatePassword } from '../../api/user';
import toast from 'react-hot-toast';
import Spinner from '../../components/ui/Spinner';

export default function ProfilePasswordForm() {
  const { t } = useTranslation();

  const { isLoading, mutate: handleChangePassword } = useUpdatePassword();

  const initialValues: ChangePasswordRequestBody = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };

  const formik = useFormik<ChangePasswordRequestBody>({
    initialValues,
    validationSchema: createProfilePasswordSchema(t),
    onSubmit: async values => {
      handleChangePassword(values, {
        onSuccess: () => {
          formik.resetForm();
          toast.success(t('passwordUpdated'));
        },
      });
    }
  });

  return (
    <form
      className="flex flex-col gap-6 px-4  max-lg:items-center lg:gap-8 lg:px-[10%]"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex w-full flex-col gap-14">
        <h2 className="text-2xl font-semibold">{t('changePwd')}</h2>
        <div className="grid grid-cols-1  gap-x-20 gap-y-8 lg:grid-cols-2">
          <InputField
            name="oldPassword"
            label="currentPassword"
            type="password"
            placeholder="enterPassword"
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
            error={formik.errors.oldPassword}
          />

          <InputField
            name="password"
            label="newPassword"
            type="password"
            placeholder="enterPassword"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />

          <InputField
            name="confirmPassword"
            label="confirmPassword"
            type="password"
            placeholder="confirmPassword"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            error={formik.errors.confirmPassword}
          />
        </div>
        <div className="flex items-center justify-end gap-4">
          <PrimaryButton
            type="reset"
            className='!bg-white !text-primaryBlack !border !border-primaryBlack'
          >
            {t("cancel")}
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <Spinner className='mx-auto' /> : t('update')}
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
}
