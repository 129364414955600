import { useTranslation } from "react-i18next";

interface SubmitResponseProps {
  errorCodeStr?: string;
  message?: string;
}

export default function SubmitResponseHandler({ errorCodeStr, message }: SubmitResponseProps) {
  const { t } = useTranslation();

  const errorMessages: Record<string, string> = {
    Success: `${t("success")}`,
    Unauthorized: `${t("unauthorized")}`,
    Forbidden: `${t("wrongCredentials")}`,
    NotFound: `${t("notFound")}`,
    AlreadyExist: t("accountAlreadyExist"),
    Failed: `${t("failed")}`,
    PasswordPendingApproval: `${t("passwordPendingApproval")}`,
    UserAccountDeactivated: `${t("userAccountDeactivated")}`,
    Constraint: `${t("constraint")}`,
    UnreachableThirdPartyService: `${t("unreachableThirdPartyService")}`,
    WrongExtension: `${t("wrongExtension")}`,
    Fatal: `${t("failed")}`,
    BadRequest: `${t(message || "badRequest")}`,
  };
  let errorMessage = errorCodeStr ? errorMessages[errorCodeStr] : `${t("failed")}`;

  if (errorCodeStr === "AlreadyExist") {
    switch (true) {
      case message?.toLocaleLowerCase().includes("tenant"):
        errorMessage = `${t("tenant")} ${t("alreadyExist")}`;
        break;
      default:
        break;
    }
  }

  switch (message) {
    case "Can't close registre more than once a day":
      errorMessage = `${t("canNotCloseRegistreMoreThanOnceADay")}`;
      break;
  }

  return <p>{errorMessage}</p>;
}
