export interface TranslateValue {
    value: string;
    valueAr: string;
    valueFr: string;
}

export function TranslateValue(value: TranslateValue, lang: string): string {

    switch (lang) {
        case "en":
        default:
            return value.value;
        case 'ar':
            return value.valueAr;
        case 'fr':
            return value.valueFr;
    }

}