import { FunctionComponent, SVGProps, useState } from "react";
import { ReactComponent as PasswordOffIcon } from "../../assets/CRUD/passwordOff.svg";
import { ReactComponent as PasswordOnIcon } from "../../assets/CRUD/passwordOn.svg";
import { useTranslation } from "react-i18next";

interface InputsProps {
  type: React.InputHTMLAttributes<HTMLInputElement>["type"];
  label: string;
  name: string;
  placeholder: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

export default function AuthInput({
  Icon,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  error,
}: InputsProps): React.JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const isPassword = type === "password";

  return (
    <div className="flex flex-col gap-2">
      <label
        htmlFor={name}
        className="font-inter text-base font-medium text-primaryBlack"
      >
        {t(label)}
      </label>
      <div className="relative">
        <input
          type={isPassword && showPassword ? "text" : type}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
          placeholder={t(placeholder)}
          className={`font-inter w-full rounded-lg border px-5 py-3.5 placeholder:text-primaryGray focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primaryGold ${error ? "border-red-500" : "border-primaryGray"}`}
        />
        {isPassword ? (
          <button
            type="button"
            onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)}
            className="absolute end-4 top-1/2 -translate-y-1/2 text-primaryGray *:size-6"
          >
            {!showPassword ? <PasswordOnIcon /> : <PasswordOffIcon />}
          </button>
        ) : (
          Icon && <Icon className="absolute end-4 top-1/2 size-6 -translate-y-1/2 text-primaryGray" />
        )}
      </div>
      {error && <span className="text-sm font-normal text-red-500">{t(error)}</span>}
    </div>
  );
}
