import LanguageModal from "../LanguageModal";
import Logo from "../Logo";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WorkspaceSelector from "./Workspace";
import ProfileModal from "../Profile";
import CaisseSelector from "./CaisseSelector";
import { useAuth } from "../../contexts/Auth";

interface Navlinks {
  name: string;
  href: string;
  permissions: string;
}

export default function Navbar(): React.JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { permissions } = useAuth();

  const isActive = (path: string) => pathname !== "/dashboard/stats" ? pathname.includes(path) : pathname === path;

  const navLinks: Navlinks[] = [
    {
      href: "/dashboard",
      name: t("tenants"),
      permissions: permissions?.registerTenant || "",
    },
    {
      href: "/dashboard/stats",
      name: t("stats"),
      permissions: permissions?.registerStats || ""
    },
  ];

  return (
    <header className="flex items-center justify-between bg-white px-8 py-2.5">
      <div className="flex items-center gap-4 w-80">
        <section className="flex flex-col items-center">
          <Logo className="ltr:size-[58px] rtl:size-[77px]" />
          <WorkspaceSelector />
        </section>
        <CaisseSelector />
      </div>
      <section className="flex gap-10">
        {navLinks.map(nav => (
          nav.permissions.includes("R") &&
          <Link
            key={nav.href}
            to={nav.href}
            className={`${isActive(nav.href) ? "font-semibold text-primaryGreen" : "font-medium"} text-lg duration-150 hover:text-primaryGreen`}
          >
            {nav.name}
          </Link>
        ))}
      </section>
      <section className="flex items-center gap-7 w-80 justify-end">
        <LanguageModal />
        <ProfileModal />
      </section>
    </header>
  );
}
