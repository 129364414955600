import { useAuth } from "../contexts/Auth";
import { UserType } from "../types/data/user";

export interface RouteGuardRoleProps {
  requiredUserType: UserType[] | undefined;
}

export interface RouteGuardPermissionsProps {
  requiredPermissions: string[] | undefined;
  requiredActions: string[] | undefined;
}

export function RouteProtectionRole({ requiredUserType }: RouteGuardRoleProps): boolean {
  const { userData } = useAuth();

  const hasRequiredRole = () =>
    requiredUserType !== undefined
      ? userData
        ? requiredUserType.includes(userData.type)
        : requiredUserType.includes("guest")
      : true;

  return hasRequiredRole();
}

export function RouteProtectionPermissions({
  requiredPermissions,
  requiredActions,
}: RouteGuardPermissionsProps): boolean {
  const { permissions } = useAuth();

  const hasRequiredPermissions = () =>
    requiredPermissions !== undefined && requiredActions !== undefined
      ? requiredPermissions?.every(
        requiredPermission =>
          permissions &&
          permissions[requiredPermission] !== null &&
          requiredActions?.every(action => permissions[requiredPermission]?.includes(action)),
      )
      : true;

  return hasRequiredPermissions();
}
