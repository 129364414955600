import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ParentRoutes, routes } from "./routeStrucutre";
import { RouteProtectionPermissions, RouteProtectionRole } from "./RouteProtection";
import NotFound from "../pages/404";
import RefetchOnChange from "../components/ui/refetchOnChange";

export default function RouterComponent() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, i) => {
          if (!RouteProtectionRole({ requiredUserType: route.requiredUserType }))
            return (
              <Route
                key={i}
                path={route.path}
                element={<Navigate to={"/"} />}
              />
            );

          if (
            !RouteProtectionPermissions({
              requiredActions: route.requiredActions,
              requiredPermissions: route.requiredPermissions,
            })
          )
            return (
              <Route
                key={i}
                path={route.path}
                element={<Navigate to={"/not-authorized"} />}
              />
            );

          return (
            <React.Fragment key={i}>
              {route.parent ? (
                ParentRoutes.map((parentRoute, i) => (
                  <Route
                    key={i}
                    path={parentRoute.path}
                    element={parentRoute.component && <parentRoute.component />}
                  >
                    {route.parent === parentRoute.path && (
                      <Route
                        key={i}
                        path={route.path}
                        element={<route.component />}
                      />
                    )}
                  </Route>
                ))
              ) : (
                <Route
                  key={i}
                  path={route.path}
                  element={<route.component />}
                />
              )}
            </React.Fragment>
          );
        })}
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
      <RefetchOnChange />
    </BrowserRouter>
  );
}
