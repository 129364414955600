import ProfileInfoForm from './ProfileInfoForm';
import ProfilePasswordForm from './ProfilePasswordForm';

export default function Profile(): React.JSX.Element {
  return (
    <article className="w-full">
      <ProfileInfoForm />
      <ProfilePasswordForm />
    </article>
  );
}
