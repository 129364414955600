import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import { useOTPVerify } from "../../api/user";
import Spinner from "../../components/ui/Spinner";

export default function MagicLinkSentSuccess(): React.JSX.Element {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const otpLength: number = useMemo(() => 8, []);

    const { handleLoginSuccess } = useAuth();
    const { isLoading, mutate: otpVerify } = useOTPVerify();
    const navigate = useNavigate();



    useEffect(() => {
        const form = formRef.current;
        if (!form) return;

        const inputs = Array.from(form.querySelectorAll('input[type=text]')) as HTMLInputElement[];
        const submit = form.querySelector('button[type=submit]') as HTMLButtonElement;

        const handleKeyDown = (e: KeyboardEvent) => {
            if (
                !/^[0-9]{1}$/.test(e.key)
                && e.key !== 'Backspace'
                && e.key !== 'Delete'
                && e.key !== 'Tab'
                && e.key !== 'v' && e.key !== 'V'
                && !e.metaKey
            ) {
                e.preventDefault();
            }

            if (e.key === 'Delete' || e.key === 'Backspace') {
                const index = inputs.indexOf(e.target as HTMLInputElement);

                if (index <= 0) return;

                inputs[index - 1].value = '';
                inputs[index - 1].focus();
            }
        };

        const handleInput = (e: Event) => {
            const { target } = e;
            const index = inputs.indexOf(target as HTMLInputElement);
            if ((target as HTMLInputElement).value) {
                if (index < inputs.length - 1) {
                    inputs[index + 1].focus();
                } else {
                    submit?.focus();
                }
            }
        };

        const handleFocus = (e: FocusEvent) => {
            (e.target as HTMLInputElement).select();
        };

        const handlePaste = (e: ClipboardEvent) => {
            e.preventDefault();
            const text = e.clipboardData?.getData('text') || '';
            console.log(text);
            if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
                return;
            }
            const digits = text.split('');
            inputs.forEach((input, index) => input.value = digits[index]);
            submit?.focus();
        };

        inputs.forEach((input) => {
            input.addEventListener('input', handleInput);
            input.addEventListener('keydown', handleKeyDown);
            input.addEventListener('focus', handleFocus);
            input.addEventListener('paste', handlePaste);
        });
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const form = e.currentTarget;
        const inputs = Array.from(form.querySelectorAll('input[type=text]')) as HTMLInputElement[];
        const otp = inputs.map(input => input.value).join('');

        if (otp.length !== otpLength)
            return;

        otpVerify(otp, {
            onSuccess: (data) => {
                handleLoginSuccess(data);
                navigate('/');
            }
        });

    }

    return (
        <article className="flex items-center justify-center h-screen w-screen">
            <div className=" mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
                <header className="mb-8">
                    <h1 className="text-2xl font-bold mb-1">{t("otpTitle")}</h1>
                    <p className="text-[15px] text-slate-500">{t("otpDescription")}</p>
                </header>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="flex items-center justify-center gap-3">
                        {Array.from({ length: otpLength }).map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-primaryGreen focus:ring-2 focus:ring-primaryGreen/20"
                                maxLength={1}
                                autoComplete="off"
                                pattern="[0-9]{1}"
                            />
                        ))}
                    </div>
                    <div className="max-w-[260px] mx-auto mt-6">
                        <PrimaryButton className="w-full !text-sm flex items-center justify-center !font-normal">
                            {isLoading ? <Spinner /> : t("verifyCode")}
                        </PrimaryButton>

                    </div>
                </form>
                <div className="text-sm text-slate-500 mt-4">
                    {t("didntReceiveCode")}
                    &nbsp;
                    <Link className="font-medium text-primaryGreen hover:scale-110 duration-150" to="/">{t("retry")}</Link></div>
            </div>

        </article>

    );
}