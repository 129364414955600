import { useTranslation } from "react-i18next";
import usePopup from "../../hooks/usePopup";
import { useAuth } from "../../contexts/Auth";
import { useCreateRubrique, useGetRubriques } from "../../api/rubriques";
import { RubriqueRequestBody } from "../../types/request/RubriqueRequest";
import { useCaisse } from "./Caisse/Provider";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Spinner from "../../components/ui/Spinner";
import InputSelect from "../../components/Inputs/InputSelect";
import InputField from "../../components/Inputs/InputField";
import { createRegisterRubriqueSchema } from "./validator";
import { TranslateValue } from "../../utils/TranslateValueUtils";
import { RubriqueResponseBody } from "../../types/response/RubriqueResponse";
import { useEffect, useState } from "react";

interface RubriqueModalProps {
    onClose: () => void;
    rubriquesId: string[];
}

export default function RubriqueModal({ onClose, rubriquesId }: RubriqueModalProps): React.JSX.Element {
    usePopup();
    const { t, i18n } = useTranslation();
    const { selectedCaisse, selectedWorkspace } = useAuth();
    const { isLoading, mutate: createRubrique } = useCreateRubrique();
    const { data } = useGetRubriques();
    const { tenantId } = useCaisse();

    const [selectedRubrique, setSelectedRubrique] = useState<RubriqueResponseBody | null>(null);

    const rubriques = data?.filter(rubrique => !rubriquesId.includes(rubrique.id));
    const MAX_RUBRIQUE = 6;

    const initialValues: RubriqueRequestBody = {
        workspaceId: selectedWorkspace?.workspaceId || "",
        registryId: selectedCaisse?.id || "",
        tenantId: tenantId,
        amount: selectedRubrique?.amount || 0,
        rubriqueId: selectedRubrique?.id || "",
    };

    const formik = useFormik<RubriqueRequestBody>({
        initialValues,
        validationSchema: createRegisterRubriqueSchema(t, MAX_RUBRIQUE <= rubriquesId.length),
        enableReinitialize: true,
        onSubmit: (values) => {
            createRubrique(values, {
                onSuccess: () => {
                    toast.success(t("rubriqueSuccess"));
                    onClose();
                }
            });
        },
    });

    useEffect(() => {
        if (formik.values.rubriqueId) {
            setSelectedRubrique(rubriques?.find(rubrique => rubrique.id === formik.values.rubriqueId) || null);
        }
    }, [formik.values.rubriqueId, rubriques]);


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
            <div className="relative z-10 flex w-[500px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg">
                <div className="flex justify-end">
                    <button
                        className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
                        onClick={onClose}
                    >
                        X
                    </button>
                    <h1 className=" text-xl font-semibold">{t("addRubrique")}</h1>
                </div>
                <form className="w-full space-y-8" onSubmit={formik.handleSubmit}>
                    <section className="space-y-4">
                        <InputSelect
                            name="rubriqueId"
                            placeholder="selectRubrique"
                            onChange={formik.handleChange}
                            options={rubriques?.filter(
                                r => r.amount > 0 || r.isVariableAmount
                            ).map(rubrique => ({
                                value: rubrique.id,
                                label:
                                    TranslateValue({
                                        value: rubrique.name,
                                        valueAr: rubrique.nameAr,
                                        valueFr: rubrique.nameFr,
                                    },
                                        i18n.language
                                    ),
                            })) || []}
                            value={formik.values.rubriqueId}
                            error={formik.errors.rubriqueId}
                        />
                        {selectedRubrique?.isVariableAmount && (
                            <InputField
                                name="amount"
                                label="amount"
                                onChange={formik.handleChange}
                                placeholder=""
                                type="number"
                                value={formik.values.amount}
                                error={formik.errors.amount}
                            />
                        )}
                    </section>
                    <section className="flex justify-center gap-4">
                        <PrimaryButton
                            type="reset"
                            onClick={onClose}
                            className="!bg-destructive-500"
                        >
                            {t("cancel")}
                        </PrimaryButton>
                        <PrimaryButton
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner /> : t("confirm")}
                        </PrimaryButton>
                    </section>
                </form>
            </div>
            <div
                className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
                onClick={onClose}
            />
        </div>
    );
}