import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import { useVerifyMagicLink } from "../../api/user";
import { useAuth } from "../../contexts/Auth";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export default function MagicLink(): React.JSX.Element {
    const { handleLoginSuccess } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const verificationToken = searchParams.get("verificationToken") || "";

    const { isLoading } = useVerifyMagicLink(verificationToken, {
        onSuccess: (data) => {
            handleLoginSuccess(data);
            toast.success(t("loginSuccess"));
            navigate("/dashboard");
        },
        onError: (error) => {
            error?.message?.includes("verification token") && navigate("/not-authorized");
        }
    });

    if (isLoading)
        return (
            <section className="flex h-screen w-full items-center justify-center">
                <Spinner />
            </section>
        );

    return (
        <Spinner />
    );
}