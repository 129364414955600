import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import { begin, rowsPerPage } from "../utils/rowsPerPage";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { InvoiceResponseBody } from "../types/response/InvoiceResponse";
import { ErrorResponse, ListResponse } from "../types/response";
import { useAuth } from "../contexts/Auth";
import { CancelInvoiceRequestBody } from "../types/request/InvoiceRequest";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";

export function useGetInvoices(
  tenantId: string,
  workspaceId: string,
  options?: Omit<UseQueryOptions<ListResponse<InvoiceResponseBody>, ErrorResponse>, "queryKey" | "queryFn">,
) {
  const [searchParams] = useSearchParams();

  async function getInvoices() {
    const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

    try {
      const response = await axiosInstance.get(
        ENDPOINTS.TENANTS_GET_TENANT_INVOICES_ENDPOINT + `${workspaceId}/${tenantId}`,
        {
          params: {
            begin: begin(currentPage, rowsPerPage),
            count: rowsPerPage,
          },
        },
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useQuery<ListResponse<InvoiceResponseBody>, ErrorResponse>(
    ["invoices", searchParams.get("page"), tenantId, workspaceId],
    getInvoices,
    {
      ...options,
    },
  );
}

export function useGetLatestTransactions(
  options?: Omit<UseQueryOptions<InvoiceResponseBody, ErrorResponse>, "queryKey" | "queryFn">,
) {
  const { id } = useAuth().selectedCaisse || { id: "" };

  async function getLatestTransactions() {
    try {
      const response = await axiosInstance.get(ENDPOINTS.CAISSE_GET_LATEST_TRANSACTIONS_ENDPOINT + id + "/lastTransaction");

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }


  return useQuery<InvoiceResponseBody, ErrorResponse>(["latestTransactions", id], getLatestTransactions, {
    ...options,
  });
}

export function useCancelLatestTransactions(
  options?: Omit<UseMutationOptions<unknown, ErrorResponse, CancelInvoiceRequestBody>, "mutationKey" | "mutationFn">,
) {
  const queryClient = useQueryClient();

  async function cancelLatestTransactions(data: CancelInvoiceRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CAISSE_CANCEL_TRANSACTION_ENDPOINT, data);

      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CancelInvoiceRequestBody>(cancelLatestTransactions, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("latestTransactions");
      queryClient.invalidateQueries("tenant");
    },
    ...options,
  });
}