import { useTranslation } from "react-i18next";
import Logo from "../Logo";
import LanguageModal from "../LanguageModal";
import { Outlet } from "react-router-dom";

export default function LoginLayout(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <article className="relative h-screen">
      <img
        src="/path.png"
        alt="background"
        className="pointer-events-none absolute start-0 top-0 -z-10 h-screen transform object-cover rtl:-scale-x-100"
      />
      <header className="grid grid-cols-3 items-center justify-between px-6 py-10 sm:flex sm:items-start sm:px-8 md:px-12">
        <Logo className="size-16 md:size-24 rtl:size-20" />
        <section className="order-2 col-span-3 flex flex-col items-center *:text-center *:text-xs *:font-bold *:leading-relaxed *:lg:text-base">
          <h1 className="max-w-lg">{t("republicDz")}</h1>
          <h1 className="max-w-lg">{t("habitantMenistry")}</h1>
          <h1 className="max-w-sm">{t("complexEco")}</h1>
          <h1 className="max-w-lg">{t("opgi")}</h1>
          <h1 className="max-w-sm">{t("ePayment")}</h1>
        </section>
        <div className="col-span-2 flex justify-end sm:order-3 sm:col-span-1">
          <LanguageModal />
        </div>
      </header>

      <div className="mx-4">
        <Outlet />
      </div>
    </article>
  );
}
