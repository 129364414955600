import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Error403SVG } from "../../assets/error403.svg";
import { useAuth } from "../../contexts/Auth";

export default function NotAuthorized(): React.JSX.Element {
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <div className="absolute end-1/3 flex h-screen flex-col items-center justify-center">
        <Error403SVG />
        <Link
          to={"/"}
          className="shadow-lightBlue/80 hover:shadow-lightBlue mt-4 flex w-[200px] flex-col items-center justify-center rounded-md bg-primaryGreen py-2 text-white shadow-xl duration-150"
        >
          {t("goBackHome")}
        </Link>
        <Link
          to={"/login"}
          className="shadow-lightBlue/80 hover:shadow-lightBlue mt-4 flex w-[200px] flex-col items-center justify-center rounded-md bg-primaryGreen py-2 text-white shadow-xl duration-150"
          onClick={logout}
        >
          {t("login")}
        </Link>
      </div>
    </>
  );
}
