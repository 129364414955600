/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import {
  CaisseBrigadeRequestBody,
  CaisseChequeResponseBody,
  CaisseEspeceResponseBody,
  CaisseRequestBody,
  CaisseTpuRequestBody,
  CaisseVirementResponseBody,
  QuoteRequestBody,
} from "../types/request/CaisseRequest";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";
import { ErrorResponse } from "../types/response";
import { QuoteResponseBody } from "../types/response/CaisseResponse";
import { useAuth } from "../contexts/Auth";

export function useDownloadInvoice(options?: Omit<UseMutationOptions<any, ErrorResponse, string>, "mutationFn">) {
  async function downloadInvoice(transactionReference: string) {
    try {
      const response = await axiosInstance.get(ENDPOINTS.DOWNLOAD_INVOICE + transactionReference, {
        responseType: "blob",
      });

      return response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<any, ErrorResponse, string>(downloadInvoice, {
    ...options,
  });
}

export function usePostQuote(
  tenantId: string,
  options?: Omit<UseMutationOptions<QuoteResponseBody, ErrorResponse, QuoteRequestBody>, "mutationFn"> | undefined,
) {
  async function postQuote(values: QuoteRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CAISSE_QUOTE_TRANSACTION_ENDPOINT + tenantId, values);

      return response.data.response;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<QuoteResponseBody, ErrorResponse, QuoteRequestBody>(postQuote, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    ...options,
  });
}

export function useCreateChequeTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseChequeResponseBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  const queryClient = useQueryClient();
  const { id } = useAuth().selectedCaisse || { id: "" };

  async function createChequeTransaction(values: CaisseChequeResponseBody & CaisseRequestBody) {
    try {
      await new Promise<void>((resolve) => {
        try {
          // @ts-expect-error
          window?.chrome?.webview.postMessage(JSON.stringify(
            {
              token: localStorage.getItem("token"),
              action: "createTransaction",
              request: {
                type: values.type,
                tenantId: values.tenantId,
                numberOfMonths: values.numberOfMonths,
                transactionReference: values.transactionReference,
                rubriques: values.rubriques,
                registryId: id,
                content: JSON.stringify({
                  bank: values.bank,
                  chequeNumber: values.chequeNumber,
                  creditNumber: values.creditNumber,
                  date: values.date,
                }),
              }
            }));
          resolve();
        } catch (error) {
          resolve();
        }
      });

      return;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseChequeResponseBody & CaisseRequestBody>(createChequeTransaction, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("tenant");
      queryClient.invalidateQueries("tenantRubriques");
    },

    ...options,
  });
}

export function useCreateVirmentTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseVirementResponseBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  const queryClient = useQueryClient();
  const { id } = useAuth().selectedCaisse || { id: "" };


  async function createVirementTransaction(values: CaisseVirementResponseBody & CaisseRequestBody) {
    try {
      await new Promise<void>((resolve) => {
        try {
          // @ts-expect-error
          window?.chrome?.webview.postMessage(JSON.stringify(
            {
              token: localStorage.getItem("token"),
              action: "createTransaction",
              request: {
                type: values.type,
                tenantId: values.tenantId,
                numberOfMonths: values.months,
                transactionReference: values.transactionReference,
                rubriques: values.rubriques,
                registryId: id,
                content: JSON.stringify({
                  bank: values.bank,
                  virementNumber: values.virementNumber,
                  date: values.date,
                }),
              }
            }));
          resolve();
        } catch (error) {
          resolve();
        }
      });

      return;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseVirementResponseBody & CaisseRequestBody>(
    createVirementTransaction,
    {
      onError: error => {
        toast.error(
          <SubmitResponseHandler
            message={error.message}
            errorCodeStr={error.errorCodeStr}
          />,
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries("tenant");
        queryClient.invalidateQueries("tenantRubriques");

      },
      ...options,
    },
  );
}

export function useCreateCashTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseEspeceResponseBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  const queryClient = useQueryClient();
  const { id } = useAuth().selectedCaisse || { id: "" };


  async function createCashTransaction(values: CaisseEspeceResponseBody & CaisseRequestBody) {
    try {
      await new Promise<void>((resolve) => {
        try {
          // @ts-expect-error
          window?.chrome?.webview.postMessage(JSON.stringify(
            {
              token: localStorage.getItem("token"),
              action: "createTransaction",
              request: {
                type: values.type,
                tenantId: values.tenantId,
                numberOfMonths: values.numberOfMonths,
                transactionReference: values.transactionReference,
                rubriques: values.rubriques,
                registryId: id,
                content: JSON.stringify({
                  cashRecieved: values.price,
                  cashReturned: values.handedOver,
                }),
              }
            }));
          resolve();
        } catch (error) {
          resolve();
        }
      });

      return;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseEspeceResponseBody & CaisseRequestBody>(createCashTransaction, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("tenant");
      queryClient.invalidateQueries("tenantRubriques");
    },
    ...options,
  });
}

export function useCreateTpuTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseTpuRequestBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  const queryClient = useQueryClient();
  const { id } = useAuth().selectedCaisse || { id: "" };

  async function createTpuTransaction(values: CaisseTpuRequestBody & CaisseRequestBody) {
    try {
      await new Promise<void>((resolve) => {
        try {
          // @ts-expect-error
          window?.chrome?.webview.postMessage(JSON.stringify(
            {
              token: localStorage.getItem("token"),
              action: "createTransaction",
              request: {
                type: values.type,
                tenantId: values.tenantId,
                numberOfMonths: values.numberOfMonths,
                transactionReference: values.transactionReference,
                rubriques: values.rubriques,
                registryId: id,
                content: JSON.stringify({
                  numero: values.numero,
                  date: values.date,
                }),
              }
            }));
          resolve();
        } catch (error) {
          resolve();
        }
      });

      return;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseTpuRequestBody & CaisseRequestBody>(createTpuTransaction, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("tenant");
      queryClient.invalidateQueries("tenantRubriques");
    },
    ...options,
  });
}

export function useCreateBrigadeTransaction(
  options?:
    | Omit<UseMutationOptions<unknown, ErrorResponse, CaisseBrigadeRequestBody & CaisseRequestBody>, "mutationFn">
    | undefined,
) {
  const queryClient = useQueryClient();
  const { id } = useAuth().selectedCaisse || { id: "" };

  async function createTpuTransaction(values: CaisseBrigadeRequestBody & CaisseRequestBody) {
    try {
      await new Promise<void>((resolve) => {
        try {
          // @ts-expect-error
          window?.chrome?.webview.postMessage(JSON.stringify(
            {
              token: localStorage.getItem("token"),
              action: "createTransaction",
              request: {
                type: values.type,
                tenantId: values.tenantId,
                numberOfMonths: values.numberOfMonths,
                transactionReference: values.transactionReference,
                rubriques: values.rubriques,
                registryId: id,
                content: JSON.stringify({
                  numero: values.numero,
                  date: values.date,
                  accountId: values.accountId,
                }),
              }
            }));
          resolve();
        } catch (error) {
          resolve();
        }
      });

      return;
    } catch (error: any) {
      console.log(error?.response?.data);
      throw error?.response?.data;
    }
  }

  return useMutation<unknown, ErrorResponse, CaisseBrigadeRequestBody & CaisseRequestBody>(createTpuTransaction, {
    onError: error => {
      toast.error(
        <SubmitResponseHandler
          message={error.message}
          errorCodeStr={error.errorCodeStr}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("tenant");
      queryClient.invalidateQueries("tenantRubriques");
    },
    ...options,
  });
}
