import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

export default function RefetchOnChange() {
    const { pathname } = useLocation();
    const queryClient = useQueryClient();


    useEffect(() => {
        queryClient.invalidateQueries("ownCaisses");
    }, [pathname, queryClient]);

    useEffect(() => {
        if (!localStorage.getItem("token")) return;

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            window?.chrome?.webview.postMessage(JSON.stringify(
                {
                    token: localStorage.getItem("token"),
                    action: "login",
                }));
        } catch (error) {
            console.error(error);
        }
    }, []);

    return null;
}