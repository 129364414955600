import { TFunction } from "i18next";
import Yup from "../../utils/customYup";
import { StatsRequestBody } from "../../types/request/StatsRequest";
import { STATS_TYPES } from "../../constants";

export function createExportSchema(t: TFunction): Yup.Schema<StatsRequestBody> {
    return Yup.object().shape({
        endDate: Yup.date().typeError(t("required")),
        startDate: Yup.date().typeError(t("required")),
        type: Yup.string().required(t("required")).oneOf(Object.values(STATS_TYPES)),
        recapRecouvrementParCaisse: Yup.boolean().required(),
        etatRecouvrementParCaisse: Yup.boolean().required(),
    });
}